import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const LimbusBeginnerPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="lc">
      <ul className="breadcrumb">
        <li>
          <Link to="/limbus-company/">Limbus Company</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/limbus-company/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Beginner guide</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/limbuscompany/categories/category_beg.jpg"
            alt="Beginner"
          />
        </div>
        <div className="page-details">
          <h1>Limbus Company Beginner guide</h1>
          <h2>
            A beginner guide for Limbus Company that will help you understand
            the game.
          </h2>
          <p>
            Last updated: <strong>13/05/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          Limbus Company is a third game from studio Project Moon and its story
          takes place after events of Library of Ruina ended however knowledge
          of previous games is not needed as thanks to amnesiac at the start,
          main character Dante, all the world nuances are and will be explained.
          There are two things that need to be explained first due to their
          importance. Combat and Gacha.
        </p>
        <SectionHeader title="Combat" />
        <p>
          Core of the Limbus gameplay is combat which is not really best
          explained by tutorials. Combat consists of a mechanic called
          “Clashes”. Clash happens when 2 skills oppose each other. In Focused
          Encounters (encounters against abnormalities or bosses) players can
          select which sinner skills should clash with enemy skills granted
          sinner’s speed is faster to freely select clashes. In Limbus,
          character speed is “rolled” every turn. Each Identity has their own
          “speed range”, like let's say from 3 speed to 7 speed, and each turn
          game will roll a number between said 3 to 7 in this case. If speed of
          sinner is equal or lower than speed of the enemy, enemy will target
          their original target and “intercepting” that attack will mean only
          targeted sinner will be able to clash and not always targeted sinner
          will be able to win clashes. Clash looks like in the picture below:
        </p>
        <StaticImage
          src="../../../images/limbuscompany/starter/image15.webp"
          alt="Guide"
        />
        <p>
          Each Sinner Identity has six skills and one defensive skill. Three
          Skill 1 (S1) that are generally the weakest, two Skill 2 (S2) whose
          power vary from Identity to Identity and one Skill 3 (S3) which are
          generally the strongest or have the main strength of Identity tied to
          them. There is also always available defensive skill, from which there
          are three kinds: Evade, Guard and Counter. To get it simply click
          character portrait and defensive skill will replace 1st in slot normal
          skill.
        </p>
        <p>
          Each skill has a certain amount of “Coin” attached to them.
          Combination of Skill Base Power and Power from the Coins results in
          Final Power. For example, let's take a look at [LCB Sinner] Hong Lu
          S3:
        </p>
        <StaticImage
          src="../../../images/limbuscompany/starter/image19.webp"
          alt="Guide"
        />
        <p>
          This skill has Base Power of 6 and two coins each having a value of 4.
          When skills clash or skill is about to do damage, skill coins can flip
          Heads or Tails, which will determine Final Power. Skill above has Base
          Power of 6 meaning this is the lowest value the skill can roll if both
          coins flip Tails. However if both coins from the Whirlwind flip Heads,
          skill Final Power will result in 14. Certain skills have additional
          conditions that can boost their power in various ways, like in example
          above, Hong Lu coins will turn to 6, from 4, if he did not take any
          damage previous turn.
        </p>
        <p>
          Can skill power be increased by other means? Yes. There are two ways.
        </p>
        <h5>Offense Level</h5>
        <StaticImage
          src="../../../images/limbuscompany/starter/image18.webp"
          alt="Guide"
        />
        <p>
          Offense Level is indicated by a sword icon under the name of the
          skill. When the difference between Offense Level for either side is
          higher by 3, power of the skill IN CLASH will increase by one. It will
          increase further by another 3 Offense Level Difference. So 9 Offense
          Level difference will result in additional 3 Clash Power.
        </p>
        <StaticImage
          src="../../../images/limbuscompany/starter/image11.webp"
          alt="Guide"
        />
        <p>
          In this example, Liu Rodion skill “Fiery Knifehand-Combust” has
          Offense Level of 42 but enemy skill “Don’t You Stop Now” has 45
          Offense Level, meaning they get +1 Clash Power, indicated by the
          glowing yellow number.
        </p>
        <h5>Resonance</h5>
        <p>
          Second way of increasing Skill Power that also ties into Offense
          Level, is Resonance, a mechanic poorly explained by in-game tutorial.
        </p>
        <p>
          There are two types of Resonance. Resonance and Absolute Resonance.
          Resonance happens if two or more skill of the same Sin affinity are
          used on the dashboard but are not directly after each other, like in
          example below showing Wrath Resonance, as three Wrath Skills are used:
        </p>
        <StaticImage
          src="../../../images/limbuscompany/starter/image1.webp"
          alt="Guide"
        />
        <p>
          What normal resonance does, is increase the Offense Level of each
          skill after the first one, by 1. In the picture above, the first Wrath
          Skill gets 0 bonus Offense Level, second skill gets +1 and third skill
          gets +2.
        </p>
        <p>
          Then there is Absolute Resonance. It happens when three or more skills
          are matched on the dashboard and are after each other.
        </p>
        <StaticImage
          src="../../../images/limbuscompany/starter/image7.webp"
          alt="Guide"
        />
        <p>
          Unlike normal resonance, Absolute Resonance boosts Offense Level of
          EVERY skill in the chain, by the fixed amount, depending on the length
          of the chain (3 skills is 3 Offense, 4-5 is 5 Offense, 6-7 is 7
          Offense and so on). Let's go back to Liu Rodion’s clashing Skill:
        </p>
        <StaticImage
          src="../../../images/limbuscompany/starter/image3.webp"
          alt="Guide"
        />
        <p>
          Due to Absolute Resonance consisting of 6 Wrath Skills, her Offense
          Level increased from 42 to 49 which puts her 4 Above enemy’s Offense
          Level and now it is Rodion who receives +1 Clash Power.
        </p>
        <h5>Defense Level Calculation</h5>
        <p>
          On top of Offense Level there is also Defense Level but it does not
          matter for the Clash. It only affects received damage and Defensive
          skills. Below are the calculations of how Defense Level and Damage are
          calculated together:
        </p>
        <blockquote>
          <p>
            (Offence Level - Defense Level) ÷ (|Offence Level - Defense Level| +
            25) X 100%
          </p>
        </blockquote>
        <ul>
          <li>
            When the Defense Level is 5 higher than the Offense Level: About
            -16%
          </li>
          <li>
            When the Defense Level is 10 higher than the Offense Level: About
            -28%
          </li>
          <li>
            When the Defense Level is 30 higher than the Offense Level: About
            -54%
          </li>
          <li>
            When the Defense Level is 50 higher than the Offense Level: About
            -66%
          </li>
          <li>
            When the Defense Level is 100 higher than the Offense Level: -80%
          </li>
        </ul>
        <p>
          There is one more mechanic tied to Combat and affects chances of Coin
          flipping Heads or Tails. It is called Sanity (SP in short).
        </p>
        <p>
          Each sinner has a Sanity and majority of the fights, everybody starts
          at 0 Sanity:
        </p>
        <StaticImage
          src="../../../images/limbuscompany/starter/image17.webp"
          alt="Guide"
        />
        <p>
          Sanity is indicated by the circle with blue number in the right bottom
          corner of the character’s icon.
        </p>
        <p>
          Sanity can go up to 45 or -45. Each point of sanity increases, or in
          case of negative sanity decreases, chances to flip Heads. So at 0 SP,
          Sinner has 50% chance to flip heads while at 45 Sanity, chances to
          Flip Heads rise to 95%. Sanity can be increased by reasons like
          winning a clash or killing an enemy. Certain Identities can have
          different Sanity gain and losses requirements, noted in their “Sanity”
          page, for example here is Liu Rodion’s Sanity page:
        </p>
        <StaticImage
          src="../../../images/limbuscompany/starter/image5.webp"
          alt="Guide"
        />
        <SectionHeader title="Gacha" />
        <p>
          Limbus is in a weird spot in comparison to other gachas out there.
          Limbus monetization comes not from Gacha itself but rather Battle Pass
          that is heavily tied to the gacha system itself but let’s start with
          gacha itself.
        </p>
        <p>
          “Extraction” is the gacha system of Limbus where you use a resource
          called “Lunacy” to pull (130 per single pull, 1300 for multi). There
          are in a way four rarities of things you can get:
        </p>
        <ul>
          <li>1. 0 Rarity, Base Identities (LCB versions of Sinners)</li>
          <li>2. 00 Rarity of Identities</li>
          <li>3. 000 Rarity of Identities</li>
          <li>4. E.G.O.</li>
        </ul>
        <p>Rates look as follow:</p>
        <StaticImage
          src="../../../images/limbuscompany/starter/image8.webp"
          alt="Guide"
        />
        <p>
          However, when you pull E.G.O., it will be removed permanently from the
          pull, increasing chances of other rarities as follows:
        </p>
        <StaticImage
          src="../../../images/limbuscompany/starter/image9.webp"
          alt="Guide"
        />
        <p>
          Pity in Limbus is set at 200 rolls. At 200 you can exchange “Ideality”
          for an Identity you want. When banner ends and “Ideality” has not been
          spent, it will be converted to Thread, used to upgrade Identities and
          E.G.O.
        </p>
        <p>
          However, unlike other gacha games, generally every Identity and E.G.O.
          that will appear as a banner can be “Sharded” as in, use Sinner
          specific shards to just obtain what you want. And here is where Battle
          Pass comes in. Outside of its normal rewards like materials and
          E.G.O., once Battle Pass reaches level 120, each level afterwards
          rewards players with box of selectable Sinner Shards. Those shards can
          be exchanged for Identity or E.G.O. you want in rates of:
        </p>
        <ul>
          <li>400 shards for 000 Identity of E.G.O</li>
          <li>150 shards for 00 Identity</li>
        </ul>
        <p>
          Because of Battle Pass it is possible to obtain every Identity and
          E.G.O. from the game without touching the gacha system itself.
        </p>
        <h5>Battle Pass levels.</h5>
        <p>
          How do you farm Battle Pass levels then one may ask? Mirror Dungeon.
        </p>
        <p>
          Mirror Dungeon is farmable content that unlocks after chapter 2 and
          upon completing the run of it, players obtain Battle Bass points that
          turn into Battle Pass levels.
        </p>
        <p>
          Because of that, 11$ price of Battle Pass in Limbus over time pays off
          massively and even spending Lunacy on “Stamina” which is called in
          Limbus “Enkephalin” is considered a better option than using Lunacy
          for rolling with one exception.
        </p>
        <p>
          Every three months there is an event called Walpurgisnacht. Something
          akin to “Limited banner”. It is different from normal banners because
          when new Identities or E.G.O. appear in this banner, you can only roll
          for those. You can’t “Shard” stuff until the next Walpurignacht event
          comes. So it is generally advised TO SAVE LUNACY FOR WALPURGISNACHT as
          Identities and E.G.O. in it are quite good.
        </p>
        <p>General rules of thumb when it comes to gacha:</p>
        <ol>
          <li>
            Do not pull on E.G.O. banners – Due to how rates interact with
            E.G.O., at one point they will appear in your rolls when you roll
            for Identities and it is a waste to roll for E.G.O., specifically or
            “Shard” it.
          </li>
          <li>
            Do not use single pulls – Only multi pull has a 00 guaranteed.
            Exception is Daily pull that is using “Paid Lunacy”.
          </li>
        </ol>
        <SectionHeader title="E.G.O., Corrosion and Overclock" />
        <h5>E.G.O</h5>
        <p>
          E.G.O are basically your special skills that require you to use a
          certain amount of sins, generated when skills are used. To access
          “E.G.O screen” press and hold Identity portrait. Once done, screen
          like below will pop up, showing all currently equipped E.G.O:
        </p>
        <StaticImage
          src="../../../images/limbuscompany/starter/image6.webp"
          alt="Guide"
        />
        <p>
          After selecting E.G.O, in this case 4th Match Flame Ryoshu, 4 Wrath
          and 2 Pride will be used to make this E.G.O appear in skill chain:
        </p>
        <StaticImage
          src="../../../images/limbuscompany/starter/image4.webp"
          alt="Guide"
        />
        <p>4 Wrath and 5 Pride we had got reduced to 0 Wrath and 3 Pride:</p>
        <StaticImage
          src="../../../images/limbuscompany/starter/image10.webp"
          alt="Guide"
        />
        <p>
          Now 4th Match Flame Ryoshu E.G.O appears in the chain and can be used:
        </p>
        <StaticImage
          src="../../../images/limbuscompany/starter/image16.webp"
          alt="Guide"
        />
        <p>
          Different multiple E.G.O from same Sinner can be used in a chain
          provided they have enough skill slots:
        </p>
        <StaticImage
          src="../../../images/limbuscompany/starter/image2.webp"
          alt="Guide"
        />
        <p>
          Using E.G.O changes Sin resistances of a Sinner, as indicated below
          E.G.O screen:
        </p>
        <StaticImage
          src="../../../images/limbuscompany/starter/image13.webp"
          alt="Guide"
        />
        <p>
          On top of using Sin Resources, also consumes a certain amount of SP,
          varied from E.G.O to E.G.O. 4th Match Flame uses 20 SP, meaning Ryoshu
          would go from 22 to 2 SP so keep in mind Sanity of Sinners when using
          E.G.O because not only their chance to land heads goes down, but also
          once their SP goes into negatives, they may Corrode.{' '}
        </p>
        <h5>Corrosion</h5>
        <p>
          Corrosion turns Sinner uncontrollable and replaces their Identity
          skills with Corroded Version of Skill. Every E.G.O has Awaken skill
          (controllable and relatively safe) and Corrosion (generally
          uncontrollable and which power sometimes is better or worse than
          Awaken version). Corrosion in many cases have wording [Indiscriminate]
          included in their description, meaning friendly fire will happen. As
          long as Sinner’s SP is high, there is no worry for Corrosion however,
          as mentioned, sometimes the Corroded version might be stronger than
          Awaken, then what if we want to use Corrosion? In such cases we can
          use Overclock.
        </p>
        <h5>Overclock</h5>
        <p>
          Overclock lets players use a Corroded version of an E.G.O skill
          without worrying about friendly fire. To activate Overclock, when
          seeing all the Sinner E.G.O simply press and hold on the E.G.O you
          want to use:
        </p>
        <StaticImage
          src="../../../images/limbuscompany/starter/image12.webp"
          alt="Guide"
        />
        <p>
          Overclock requires player to spend 1.5x more resources (as in picture
          above, Blind Obsession Ryoshu normally costs 2 Gloom, 2 Pride and 2
          Envy but Overclock changes it to 3 Gloom, 2 Pride and 2 Envy) and also
          increases Sanity Drain by 1.5x. If player has enough E.G.O resources
          for Awaken version, but not for Overclock, it is still possible to
          Overclock E.G.O however it will force Corrosion:
        </p>
        <StaticImage
          src="../../../images/limbuscompany/starter/image14.webp"
          alt="Guide"
        />
        <p>
          In such a case, E.G.O will use a Corroded version of Skill, however it
          will retain [Indiscriminate] part of the Attack meaning it can damage
          friendly sinners. Paying attention to Sin owned, SP of the sinners and
          when to Overclock if needed is a vital part of certain fights, helps
          certain status teams and lets players clear Refraction Railway faster
          in some cases.
        </p>
        <SectionHeader title="Terminology" />
        <p>
          Now that 2 core things of Limbus are covered, let's explain weird
          terms that game is using and that might confuse players:
        </p>
        <ul>
          <li>
            <strong>Enkephalin</strong> – As already explained above, it is
            equivalent to “Stamina”. Only story modes use it, otherwise it can
            be converted to “Modules” that are used in other game mods.
          </li>
          <li>
            <strong>Experience Tickets</strong> – Used to level up Identities,
            increasing their Health, Defense and Offense Levels.
          </li>
          <li>
            <strong>Experience Luxcavation</strong> – Stage that consists of a
            certain amount of stages that upon completion grants Experience
            tickets. Higher levels of Luxcavation grants more tickets.
          </li>
          <li>
            <strong>E.G.O</strong> – Think of them as powerful skills that can
            be used on a certain amount of Sins that have been accumulated. Sins
            can be accumulated by using skills that are corresponding to said
            sins. For example, using Wrath Skill gives 1 Wrath Affinity
            Resource, so E.G.O that needs 2 Wrath to be used means you have to
            use two Wrath Skills first. Using E.G.O skill and if E.G.O. is at
            Threadspin Level 2, will provide Identity with a passive that will
            last until the end of the battle. Passives stack, so using TETH
            E.G.O. and HE E.G.O will result in 2 passives being now active.
          </li>
          <li>
            <strong>E.G.O Shard</strong>s – As it was explained above, E.G.O.
            Shards are used to “Shard” or buy Identities / E.G.O. from the
            Dispenser but also are used to Uptie Identities and Threadspin E.G.O
          </li>
          <li>
            <strong>Mirror Dungeons</strong> – Roguelike mode that rewards
            players with Lunacy (3 times 250 per week). Additionally clearing
            Mirror Dungeon, as it was explained, awards Battle Pass points,
            those turn into levels and levels mean Shard Boxes if Battle Pass
            reaches level 120. Gamemode where players will spend the majority of
            their time.
          </li>
          <li>
            <strong>Lunacy</strong> – Currency used for rolling gacha, refilling
            Enkephalin and buying battle pass. Lunacy can be separated into Free
            Lunacy (earned via gameplay or from compensation) and Paid (bought
            in shop)
          </li>
          <li>
            <strong>Refraction Railway</strong> – Endgame mode that happens once
            a season and lasts until the next Refraction Railway comes in. It is
            a mode where a certain amount of fights need to be cleared in X
            amount of turn, varying from Railway to Railway, in order to obtain
            rewards like Shard Boxes or Guarantee Seasonal 000 Identity tickets.
          </li>
          <li>
            <strong>Thread</strong> – Used to “Uptie” Identities to higher
            Tiers, unlocking more skills and making them more powerful or to
            “Threadspin” E.G.O to higher Tiers, making those more powerful
          </li>
          <li>
            <strong>Thread Luxcavation</strong> – Fight against Abnormality,
            rotates every day. Winning grants a certain amount of Thread. Unlike
            Experience Luxcavation, this one has a 3 times daily bonus that
            grants bonus Thread for the first 3 fights per day
          </li>
        </ul>
        <SectionHeader title="Team Building" />
        <p>
          Team Building in Limbus is centred around “status” teams, where each
          Identity is applying the same status, like Burn or Sinking, use the
          same “resource” like Poise and Charge, or teams that can pull off
          resonance of certain sin regularly, like Pride. Of course, a team made
          out of strong but not synergizing Identities can also work. To see
          examples of said teams, please refer to our “Team Building Guide”.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Beginner team ideas"
            link="/limbus-company/guides/beginner-team-ideas"
            image={
              <StaticImage
                src="../../../images/limbuscompany/categories/category_teamb.png"
                alt="Beginner team ideas"
              />
            }
          />
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default LimbusBeginnerPage;

export const Head: React.FC = () => (
  <Seo
    title="Limbus Company Beginner guide | Limbus Company | Prydwen Institute"
    description="A beginner guide for Limbus Company that will help you understand the game."
    game="limbus"
  />
);
